<template>
  <div>
    <div class="col col-md-6 col-sm-6 mx-auto">
      <b-card class=" mx-auto mt-2">
        <form @submit.prevent="registerUser(user)">
          <h1 class=" text-center">
            Registro
          </h1>
          <!--  <label for="user_identity">
            Tipo de documento.
          </label>
          <b-form-select
            v-model="user.idType"
            :options="typeDocuments"
            text-field="description"
            value-field="id"
            size="sm"
          /> -->
          <label for="user_identity">
            Numero documento de indentidad.
          </label>
          <b-form-input
            id="user_identity"
            v-model="user.idNumber"
            name="user_identity"
            type="number"
            :required="true"
            label="Numero de documento"
          />
          <!-- <label for="user_identity">
            Nombre.
          </label>
          <b-form-input
            id="user_name"
            v-model="user.nombre"
            :required="true"
            name="user_name"
            type="text"
            label="Nombre"
          /><label for="user_identity">
            Apellido.
          </label>
          <b-form-input
            id="user_lastname"
            v-model="user.apellido"
            :required="true"
            name="user_lastname"
            type="text"
            label="Apellido"
          /> -->
          <label for="user_identity">
            Numero celular.
          </label>
          <b-form-input
            id="user_mobile"
            v-model="user.celular"
            :required="true"
            name="user_mobile"
            type="number"
            label="Celular"
          /><label for="user_identity">
            Correo electrónico.
          </label>
          <b-form-input
            id="user_email"
            v-model="user.email"
            :required="true"
            name="user_email"
            type="email"
            label="Correo electronico"
          />
          <label for="user_identity">
            Contraseña.
          </label>
          <b-form-input
            id="user_password"
            v-model="user.password"
            :required="true"
            name="user_password"
            type="password"
            label="Contraseña"
          />
          <label for="user_password_repeat">
            Repetir contraseña.
          </label>
          <b-form-input
            id="user_password_repeat"
            v-model="user.passwordRepeat"
            :required="true"
            name="user_password_repeat"
            type="password"
            :state="passwordIdentical"
            label="Repite tu contraseña"
          />
          <b-button
            id="user_submitRegister"
            name="user_submitRegister"
            variant="success"
            type="submit"
            class="mt-2"
          >Registrar</b-button>
        </form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      user: {
        idType: 1,
      },
    }
  },
  computed: {
    ...mapState('typeDocuments', ['typeDocuments']),
    passwordIdentical() {
      return this.user.passwordRepeat === this.user.password
    },
  },
  methods: {
    registerUser(user) {
      if (this.passwordIdentical) {
        this.$store
          .dispatch('client/SAVE_CLIENT', user)
          .then(res => {
            if (res.data.code === 0) {
              this.login(user)
            }
            // this.user = {}
            this.$swal({
              title: 'Usuario registrado exitosamente!',
              text: res.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(err => {
            //  console.log(err)
            this.$swal({
              title: 'Error!',
              text: err.data ? err.data.msg : 'Error al registrar al cliente!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            })
          })
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Las contraseñas no son identicas',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      }
    },
    login(user) {
      const form = {
        username: user.email,
        password: user.password,
      }
      this.$store
        .dispatch('client/LOGIN_CLIENT', form)
        .then(() => {
          this.user = {}
          window.location.assign('/portal-web')
        })
        .catch(() => {
          this.$swal({
            title: 'Error!',
            text: 'Correo o contraseña invalida.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style></style>
